export default {
	// 获取页面数据
	getTableData() {

		// this.getClient();
		// this.getLogistics();
		// this.getProduct();
	},


	//获取所有客户
	getClient() {
		let params = {
			account_type_id: 3,
			page: 1,
			limit: 10000000
		}
		this.axiosPost("Client/clientList", params, function(res) {
			// console.log(res);
			if (res.data.code === 200) {
				this.opt = res.data.data.data;
				// console.log(this.opt1);
			}
		})
	},

	//获取客户下的产品
	getProduct() {
		let params = {
			// client_id:val,
			page: 1,
			limit: 10000000
		}
		this.axiosPost('Product/list', params, res => {
			if (res.data.code === 200) {
				this.opt1 = res.data.data.data;
			}
		})
	},

	//获取所有物流
	getLogistics() {
		let params = {}
		this.axiosPost("Public/logisticsList", params, function(res) {
			// console.log(res);
			if (res.data.code === 200) {
				this.opt2 = res.data.data;
				// console.log(this.opt1);
			}
		})
	},



	//添加
	add() {
		if (this.form.file == null) {
			this.msgError('请先选择要上传的文件！');
			
			return;
		}

		this.onLoad(true)
		let params = new FormData();
		if (this.form.file) {
			params.append('file', this.form.file);
			console.log('文件已添加');
		}


		this.axiosPostFile("LogisticsManagement/importLogistics", params, res => {
			console.log(res);
			if (res.data.code == 200) {
				this.onLoad(false)
				this.msgSuccess(res.data.message)
				this.form = {
					//client_id: '',
					logistics_odds: '',
					product_id: '',
					logistics_id: ''
				}
				this.fileList = []
			}else{
				this.onLoad(false)
				this.msgError('系统返回错误,请检查文件是否正确')
			}
		})
	},


	beforeUpload(file) {
		console.log(file);
		this.form.file = file;
		this.fileList = [{
			file: file,
			name: file.name
		}]
		return false;
	},
}
