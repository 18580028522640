<template>
  <div>
    <div
      class="flex justify_content_between align_items_center content-wrapper p_lr_20 m_b_20"
    >
      <div>
        <h2>物流单导入</h2>
      </div>
    </div>

    <div class="content-wrapper p_20">
      <el-form>
        <el-form-item
          label="1.选择上传文件："
        >
          <el-upload
            class="upload-demo"
            action="#"
            :before-upload="beforeUpload"
            multiple
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="2.选择客户：">
          <el-select v-model="form.client_id" @change="getProduct" size="small">
            <el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="3.选择产品：">
          <el-select v-model="form.product_id" size="small">
            <el-option 
							v-for="item in opt1"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="4.选择快递公司：">
          <el-select v-model="form.logistics_id" size="small">
            <el-option 
							v-for="item in opt2"
							:key="item.id"
							:value="item.id"
							:label="item.logistics_name"
						></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="add">确 定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script type="text/javascript">
import methods from "./method";

export default {
  data() {
    return {
      form:{
        client_id:'',
        file:'',
        product_id:'',
        logistics_id:''
      },
      opt:[],
      opt1:[],
      opt2:[],

      fileList:[]
    };
  },
  methods: methods,
  mounted() {
    this.getTableData();
  },
};
</script>
